import { Field } from '@codegateinc/react-form-builder-v2'
import { Country, CountryState } from 'lib/models'

export enum StoreSetupFields {
    StoreName = 'storeName',
    StoreDBA = 'storeDBA',
    StreetAddress = 'streetAddress',
    City = 'city',
    ZipCode = 'zipCode',
    State = 'state',
    Country = 'country'
}

export type StoreSetupShape = {
    [StoreSetupFields.StoreName]: Field<string>,
    [StoreSetupFields.StoreDBA]: Field<string>,
    [StoreSetupFields.StreetAddress]: Field<string>,
    [StoreSetupFields.City]: Field<string>,
    [StoreSetupFields.ZipCode]: Field<string>,
    [StoreSetupFields.State]: Field<CountryState | undefined>,
    [StoreSetupFields.Country]: Field<Country | undefined>
}
