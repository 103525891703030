import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { Country, CountryState, Store } from 'lib/models'
import { StoreSetupFields } from './forms'

export const useStoreSetupForm = (initialStore?: Store, initialCountry?: Country, initialState?: CountryState) => {
    const T = useTranslations()

    const storeName = useField<string>({
        key: StoreSetupFields.StoreName,
        initialValue: initialStore?.storeName || '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.storeSetup.legalName.label,
        placeholder: T.forms.storeSetup.legalName.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.storeSetup.legalName.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.storeSetup.legalName.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const storeDBA = useField<string>({
        key: StoreSetupFields.StoreDBA,
        initialValue: initialStore?.storeDBA || '',
        isRequired: false,
        validateOnBlur: true,
        label: T.forms.storeSetup.dba.label,
        placeholder: T.forms.storeSetup.dba.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.storeSetup.dba.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const streetAddress = useField<string>({
        key: StoreSetupFields.StreetAddress,
        initialValue: initialStore?.streetAddress || '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.storeSetup.street.label,
        placeholder: T.forms.storeSetup.street.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.storeSetup.street.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.storeSetup.street.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const city = useField<string>({
        key: StoreSetupFields.City,
        initialValue: initialStore?.city || '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.storeSetup.city.label,
        placeholder: T.forms.storeSetup.city.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.storeSetup.city.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.storeSetup.city.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const zipCode = useField<string>({
        key: StoreSetupFields.ZipCode,
        initialValue: initialStore?.zipCode || '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.storeSetup.zipCode.label,
        placeholder: T.forms.storeSetup.zipCode.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.storeSetup.zipCode.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.storeSetup.zipCode.validation.hasInvalidLength,
                validate: value => value.length >= 5 && value.length <= 20
            }
        ]
    })

    const state = useField<CountryState | undefined>({
        key: StoreSetupFields.State,
        initialValue: initialState || undefined,
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.storeSetup.state.label,
        placeholder: T.forms.storeSetup.state.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.storeSetup.state.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const country = useField<Country | undefined>({
        key: StoreSetupFields.Country,
        initialValue: initialCountry || undefined,
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.storeSetup.country.label,
        placeholder: T.forms.storeSetup.country.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.storeSetup.country.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        storeName,
        storeDBA,
        streetAddress,
        city,
        zipCode,
        state,
        country
    }
}
