import React, { useState, useEffect } from 'react'
import { Alert, View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useHasSavedBiometry, useUserAtom } from 'lib/atoms'
import { isNative, isWeb } from 'lib/common'
import { GoBack, ReKeyboardAccessory, Typography, AppLayout, BlurModal, Grid } from 'lib/components'
import { useBackHandler, useToast, useTranslations } from 'lib/hooks'
import { ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { BiometryInfo } from 'lib/types'
import { useEditSelf, useGetMe } from '../actions'
import { AddEditUserFields, useAddEditUserForm } from '../forms'
import { EditUserRequest } from '../types'
import { EditPasswordModal, EditSelf } from '../components'

type AddEditUserScreenProps = {
    navigation: StackProps<ScreenNames>
}

export const EditSelfScreen: React.FunctionComponent<AddEditUserScreenProps> = ({ navigation }) => {
    const T = useTranslations()
    const [isChangePasswordModal, setIsChangePasswordModal] = useState(false)
    const { styles } = useStyles(stylesheet)
    const { actions: { showSuccessToast } } = useToast()
    const { mutate: editUser, isLoading: isEditingUser } = useEditSelf()
    const [user, setUser] = useUserAtom()
    const { refetch: getUserDetails } = useGetMe()
    const [biometryInfo, setBiometryInfo] = useHasSavedBiometry()
    const { form, submit, resetForm, setFieldValue } = useForm(useAddEditUserForm(user, false, isChangePasswordModal), {
        onSuccess: ({ email, password, fullName }) => {
            // securityGroup will be used in the future
            const editedUser: EditUserRequest = {
                userUUID: user.userUUID,
                email,
                fullName: fullName || undefined,
                password: password || undefined
            }

            // reset biometry if changed password
            if (password && biometryInfo.userType === user.userType) {
                setBiometryInfo({} as BiometryInfo)
            }

            return editUser(editedUser, {
                onSuccess: ({ data }) => {
                    setUser(prevState => ({
                        ...prevState,
                        ...editedUser
                    }))
                    resetForm()
                    setIsChangePasswordModal(false)

                    if (isNative) {
                        return navigation.navigate(ScreenNames.CustomModal, {
                            title: T.common.success,
                            message: data.shouldConfirmEmail
                                ? T.screens.addEditUser.successEditedWithEmail
                                : T.screens.addEditUser.successEdited,
                            onClose: () => navigation.navigate(ScreenNames.Home)
                        })
                    }

                    getUserDetails()
                    showSuccessToast(T.screens.addEditUser.successEdited)
                },
                onError: () => Alert.alert(T.alerts.oops, T.screens.addEditUser.errorEdit)
            })
        }
    })

    useEffect(() => {
        setFieldValue(AddEditUserFields.FullName, user.fullName)
        setFieldValue(AddEditUserFields.Email, user.email)
    }, [user])

    useBackHandler(() => isEditingUser)

    return (
        <AppLayout>
            <Grid.Background
                hasPaddingVertical={isWeb}
                hasPaddingHorizontal={isWeb}
            >
                {isNative && (
                    <React.Fragment>
                        <View style={styles.header}>
                            <Typography.Heading>
                                {T.titles.editSelf}
                            </Typography.Heading>
                        </View>
                        <GoBack disabled={isEditingUser} />
                    </React.Fragment>
                )}
                <BlurModal
                    onClose={() => {
                        setIsChangePasswordModal(false)
                        resetForm()
                    }}
                    isVisible={isChangePasswordModal}
                    children={(
                        <EditPasswordModal
                            form={form}
                            onClose={() => {
                                setIsChangePasswordModal(false)
                                resetForm()
                            }}
                            onPasswordChange={submit}
                        />
                    )}
                />
                {isNative ? (
                    <ReKeyboardAccessory
                        numberOfInputs={3}
                        contentContainerStyle={styles.nativeForm}
                    >
                        {keyboardProps => {
                            const [
                                fullNameProps,
                                passwordProps,
                                emailProps
                            ] = keyboardProps

                            return (
                                <EditSelf
                                    form={form}
                                    fullNameProps={fullNameProps}
                                    passwordProps={passwordProps}
                                    emailProps={emailProps}
                                    onSubmit={submit}
                                    resetForm={resetForm}
                                    isChangePasswordModal={isChangePasswordModal}
                                    setIsChangePasswordModal={setIsChangePasswordModal}
                                    isEditingUser={isEditingUser}
                                />
                            )
                        }}
                    </ReKeyboardAccessory>
                ) : (
                    <View style={styles.form}>
                        <EditSelf
                            form={form}
                            isChangePasswordModal={isChangePasswordModal}
                            setIsChangePasswordModal={state => setIsChangePasswordModal(state)}
                            onSubmit={submit}
                            resetForm={resetForm}
                            isEditingUser={isEditingUser}
                        />
                    </View>
                )}
            </Grid.Background>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    form: {
        borderRadius: 12,
        width: '100%',
        maxWidth: {
            sm: 728
        },
        padding: {
            sm: theme.gap * 3
        },
        backgroundColor: {
            sm: theme.colors.littleMoreDarkerGray
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    nativeForm: {
        paddingHorizontal: theme.contentPaddingMobile
    }
}))
