import React from 'react'
import { KeyboardAvoidingView, Modal, ViewStyle } from 'react-native'
import { BlurView } from 'expo-blur'
import { isNative } from 'lib/common'
import { createStyles, hexToRGBA, useStyles } from 'lib/styles'
import { Children } from 'lib/types'
import { Touchable } from './Touchable'

type BlurModalProps = {
    isVisible: boolean,
    enableKeyboardAvoiding?: boolean,
    children: Children,
    style?: ViewStyle,
    backgroundStyle?: ViewStyle,
    onClose: VoidFunction
}

export const BlurModal: React.FunctionComponent<BlurModalProps> = ({
    isVisible,
    enableKeyboardAvoiding = true,
    onClose,
    style,
    backgroundStyle = {},
    children
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Modal
            transparent
            visible={isVisible}
            animationType="fade"
            statusBarTranslucent
            onRequestClose={onClose}
        >
            <BlurView
                style={styles.blur}
                intensity={isNative ? 2 : 10}
                tint="dark"
                // todo
                // reducedTransparencyFallbackColor={theme.colors.mixTransparent(theme.colors.black, 0.5)}
            />
            <Touchable
                onPress={onClose}
                style={{
                    ...styles.modalBackground,
                    ...backgroundStyle
                }}
                activeOpacity={1}
            >
                <KeyboardAvoidingView
                    enabled={enableKeyboardAvoiding}
                    behavior="position"
                >
                    <Touchable // second touchable to disable first one on content
                        activeOpacity={1}
                        style={{
                            ...styles.container,
                            ...style
                        }}
                    >
                        {children}
                    </Touchable>
                </KeyboardAvoidingView>
            </Touchable>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    modalBackground: {
        flex: 1,
        paddingHorizontal: theme.utils.gap(3),
        justifyContent: 'center',
        alignItems: {
            sm: 'center'
        },
        cursor: {
            sm: 'default'
        },
        backgroundColor: hexToRGBA(theme.colors.gray, 0.5)
    },
    container: {
        padding: theme.utils.gap(3),
        alignItems: 'center',
        borderRadius: theme.gap * 6,
        backgroundColor: theme.ui.background,
        cursor: 'default'
    },
    blur: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }
}))
