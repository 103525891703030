import React, { RefObject } from 'react'
import { Keyboard, ScrollViewProps, TextInput, View, ViewStyle } from 'react-native'
import Animated from 'react-native-reanimated'
import { KeyboardAccessoryView } from 'react-native-keyboard-accessory'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Typography, Grid } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { Touchable } from '../Touchable'
import { KeyboardAccessoryFieldProps } from './types'
import { useKeyboardAccessoryCalculations } from './useKeyboardAccessoryCalculations'

type CustomKeyboardAccessoryProps = {
    numberOfInputs: number,
    animatedExtraHeight?: Animated.SharedValue<number>,
    contentContainerStyle?: ViewStyle,
    scrollViewProps?: ScrollViewProps,
    centerComponent?: JSX.Element,
    canGoNext?(activeIndex: number): boolean,
    children(props: Array<KeyboardAccessoryFieldProps>): React.ReactChild,
}

export const CustomKeyboardAccessory: React.FunctionComponent<CustomKeyboardAccessoryProps> = ({
    children,
    numberOfInputs,
    scrollViewProps = {},
    animatedExtraHeight,
    centerComponent,
    canGoNext,
    contentContainerStyle = {}
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { scrollViewRef, childrenProps, activeInputIndex, scrollHandler } = useKeyboardAccessoryCalculations(
        numberOfInputs,
        animatedExtraHeight
    )
    const isNextDisabled = canGoNext
        ? !canGoNext(activeInputIndex)
        : activeInputIndex === childrenProps.length - 1
    const isPrevDisabled = activeInputIndex === 0

    const onPrevPress = () => {
        const prevIndex = activeInputIndex - 1
        const prevRef = childrenProps[prevIndex].ref as RefObject<TextInput>

        if (prevRef.current) {
            prevRef.current.focus()
        }
    }

    const onNextPress = () => {
        const nextIndex = activeInputIndex + 1
        const nextRef = childrenProps[nextIndex].ref as RefObject<TextInput>

        if (nextRef.current) {
            nextRef.current.focus()
        }
    }

    return (
        <View style={styles.container}>
            <Animated.ScrollView
                // @ts-ignore
                ref={scrollViewRef}
                scrollEventThrottle={16}
                onScroll={scrollHandler}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={contentContainerStyle}
                keyboardShouldPersistTaps="handled"
                {...scrollViewProps}
            >
                {children(childrenProps)}
            </Animated.ScrollView>
            <KeyboardAccessoryView
                onKeyboardShowDelay
                style={styles.accessoryContainer}
                androidAdjustResize
                avoidKeyboard
            >
                <View style={styles.row}>
                    <View style={styles.row}>
                        <Touchable
                            style={{
                                transform: [
                                    {
                                        scaleY: -1
                                    }
                                ]
                            }}
                            disabled={isPrevDisabled}
                            onPress={onPrevPress}
                        >
                            <Icons.ArrowDown
                                size={26}
                                strokeWidth={5}
                                forceColor={isPrevDisabled
                                    ? theme.colors.mixTransparent(theme.ui.primary, 0.5)
                                    : theme.ui.primary
                                }
                            />
                        </Touchable>
                        <Grid.Gap gapRight={2}/>
                        <Touchable
                            disabled={isNextDisabled}
                            onPress={onNextPress}
                        >
                            <Icons.ArrowDown
                                size={26}
                                strokeWidth={5}
                                forceColor={isNextDisabled
                                    ? theme.colors.mixTransparent(theme.ui.primary, 0.5)
                                    : theme.ui.primary
                                }
                            />
                        </Touchable>
                    </View>
                    {centerComponent}
                    <Touchable onPress={Keyboard.dismiss}>
                        <Typography.Regular
                            bold
                            forceColor={theme.ui.primary}
                        >
                            {T.common.close}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </KeyboardAccessoryView>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    accessoryContainer: {
        minHeight: 60,
        width: Measurements.WindowWidth,
        padding: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(4),
        justifyContent: 'center',
        backgroundColor: theme.ui.background
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}))
