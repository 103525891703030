import React, { useEffect, useState } from 'react'
import { ActivityIndicator, FlatList, View, ViewStyle, useWindowDimensions } from 'react-native'
import Animated, {
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withTiming
} from 'react-native-reanimated'
import { Touchable, FadeShadow, Grid, Typography, FormComponents, AnimatedArrow } from 'lib/components'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { DropdownOption } from 'lib/types'
import { R } from 'lib/utils'
import { Input } from './TextInput'

export type DropdownProps<ValueType> = {
    disabled?: boolean,
    style?: ViewStyle,
    errorMessage?: string,
    emptyMessage?: string,
    rightIcon?: JSX.Element,
    leftIcon?: JSX.Element,
    value: Array<DropdownOption<ValueType>>,
    items: Array<DropdownOption<ValueType>>,
    label?: string,
    moveFromLeft?: number,
    placeholder?: string,
    currentText?: string,
    shouldClose?: boolean,
    isLoading?: boolean,
    isMultiSelect?: boolean,
    onFetchNextPage?: VoidFunction,
    isFetchingNextPage?: boolean,
    onToggle?(isOpen: boolean): void,
    renderLabel(item: DropdownOption<ValueType>): JSX.Element,
    onChangeValue(newItem: Array<DropdownOption<ValueType>>): void,
    getItemKey?(item: DropdownOption<ValueType>): string,
    getItemLabel?(item: DropdownOption<ValueType>): string
}

export const DropdownMultiplePicker = <T,>({
    value,
    items,
    style,
    disabled,
    currentText,
    emptyMessage,
    rightIcon,
    moveFromLeft,
    placeholder,
    onChangeValue,
    shouldClose,
    isLoading,
    onToggle,
    getItemKey,
    getItemLabel,
    renderLabel,
    onFetchNextPage,
    isFetchingNextPage,
    ...inputFormProps
}: DropdownProps<T>) => {
    const dimensions = useWindowDimensions()
    const [availableHeight, setAvailableHeight] = useState(0)
    const { styles, theme } = useStyles(stylesheet)
    const [isToggled, setToggled] = useState(false)
    const [inputPositionY, setInputPositionY] = useState<number>(0)
    const [containerDimensions, setContainerDimensions] = useState({
        width: 0,
        height: 0,
        fromTop: 0
    })
    const CONTENT_HEIGHT = availableHeight
    const CONTENT_OFFSET = theme.gap // to cover borderRadius of input
    const SHADOW_HEIGHT = theme.utils.gap(6)
    const animatedArrowRotation = useSharedValue(0)
    const animatedContentHeight = useSharedValue(0)
    const animatedContentStyles = useAnimatedStyle(() => ({
        height: items.length * 82,
        maxHeight: animatedContentHeight.value,
        top: inputPositionY + theme.components.input.height - CONTENT_OFFSET - 4
    }))
    const animatedShadowStyles = useAnimatedStyle(() => ({
        position: 'absolute',
        top: inputPositionY + theme.components.input.height + animatedContentHeight.value - SHADOW_HEIGHT / 2
    }))

    const toggleContent = () => {
        cancelAnimation(animatedArrowRotation)
        cancelAnimation(animatedContentHeight)

        setToggled(!animatedContentHeight.value)
        R.ifDefined(onToggle, onToggle => onToggle(!animatedContentHeight.value))

        animatedContentHeight.value = withTiming(animatedContentHeight.value ? 0 : CONTENT_HEIGHT)
        animatedArrowRotation.value = withTiming(animatedArrowRotation.value ? 0 : -180)
    }

    useEffect(() => {
        if (shouldClose && isToggled) {
            toggleContent()
        }
    }, [shouldClose])

    useEffect(() => {
        if (isToggled) {
            animatedContentHeight.value = withTiming(CONTENT_HEIGHT)
        }
    }, [items])

    useEffect(() => {
        setAvailableHeight(dimensions.height - containerDimensions.fromTop - theme.components.input.height - theme.gap * 2)
    }, [dimensions, containerDimensions.fromTop])

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
        >
            {isToggled && (
                <Touchable
                    onPress={toggleContent}
                    style={styles.outside}
                />
            )}
            <Animated.View // platform independent shadow
                style={animatedShadowStyles}
                pointerEvents="none"
            >
                <FadeShadow
                    height={SHADOW_HEIGHT}
                    isInverted
                    style={styles.fadeShadow}
                />
            </Animated.View>
            <Touchable
                disabled={disabled || (!items?.length && !emptyMessage)}
                onPress={toggleContent}
                onLayout={event => {
                    setInputPositionY(event.nativeEvent.layout.y)
                    setContainerDimensions({
                        width: event.nativeEvent.layout.width,
                        height: event.nativeEvent.layout.height,
                        fromTop: event.nativeEvent.layout.top ?? 0
                    })
                }}
                style={styles.webContainer}
                activeOpacity={1}
            >
                <Input
                    rightIcon={
                        !disabled ? (
                            <AnimatedArrow isRotated={isToggled} />
                        ) : undefined
                    }
                    {...inputFormProps}
                    inputProps={{
                        numberOfLines: 1,
                        value: currentText,
                        placeholder: !items?.length ? emptyMessage : placeholder,
                        placeholderTextColor: theme.components.input.typography.placeholder,
                        editable: false,
                        focusable: false,
                        style: {
                            backgroundColor: disabled
                                ? theme.components.input.disabled.backgroundColor
                                : theme.components.input.backgroundColor,
                            color: isToggled
                                ? theme.ui.primary
                                : theme.components.input.typography.text,
                            paddingRight: 50,
                            ...styles.webCursor(Boolean(disabled)),
                            width: '100%',
                            ...style,
                            ...(isToggled ? styles.shadow : {})
                        }
                    }}
                />
            </Touchable>
            <Animated.View
                style={[
                    styles.content,
                    animatedContentStyles,
                    styles.borderRadiusBottom,
                    { width: containerDimensions.width },
                    conditionalStyle(Boolean(moveFromLeft), { left: moveFromLeft })
                ]}
            >
                <FlatList
                    nestedScrollEnabled
                    data={items}
                    style={{
                        ...styles.flatList,
                        ...styles.borderRadiusBottom
                    }}
                    onEndReachedThreshold={0.8}
                    keyExtractor={getItemKey}
                    onEndReached={() => R.ifDefined(onFetchNextPage, R.call)}
                    renderItem={({ item }) => {
                        const isSelected = value.map(selectedItem => selectedItem.key).includes(item.key)

                        return (
                            <FormComponents.DropdownModalTile
                                label={() => renderLabel(item)}
                                disabled={disabled}
                                customStyles={styles.dropDownTile}
                                isSelected={isSelected}
                                onPress={() => {
                                    onChangeValue(isSelected
                                        ? value.filter(valueItem => valueItem.key !== item.key)
                                        : value.concat(item)
                                    )
                                }}
                            />
                        )
                    }}
                    ListEmptyComponent={!emptyMessage ? undefined : () => (
                        <View style={styles.tile}>
                            <Typography.Regular
                                numberOfLines={1}
                                style={styles.listElement}
                                forceColor={theme.icon.error}
                            >
                                {emptyMessage}
                            </Typography.Regular>
                        </View>
                    )}
                    ListFooterComponent={() => (
                        <Grid.Gap
                            gapBottom={2}
                            gapTop={2}
                        >
                            {isFetchingNextPage && (
                                <ActivityIndicator/>
                            )}
                        </Grid.Gap>
                    )}
                    showsVerticalScrollIndicator={false}
                />
            </Animated.View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        position: 'relative',
        flexShrink: 1,
        width: '100%'
    },
    content: {
        position: 'absolute',
        width: '100%',
        zIndex: 101
    },
    dropDownTile: {
        paddingVertical: 0
    },
    flatList: {
        backgroundColor: theme.components.input.backgroundColor
    },
    fadeShadow: {
        position: 'relative',
        top: 0
    },
    shadow: theme.utils.createShadow(),
    tile: {
        width: '100%',
        justifyContent: 'center',
        paddingHorizontal: 20
    },
    webContainer: {
        width: '100%'
    },
    spacer: {
        backgroundColor: theme.colors.mixTransparent(theme.components.typography.secondary.color, 0.25),
        height: 1
    },
    borderRadiusBottom: {
        borderBottomRightRadius: theme.components.input.borderRadius,
        borderBottomLeftRadius: theme.components.input.borderRadius
    },
    borderRadiusTop: {
        borderTopLeftRadius: theme.components.input.borderRadius,
        borderTopRightRadius: theme.components.input.borderRadius
    },
    listElement: {
        fontSize: 14,
        lineHeight: 20
    },
    webCursor: (isDisabled: boolean) => ({
        cursor: !isDisabled
            ? 'pointer'
            : 'not-allowed'
    }),
    outside: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        inset: 0,
        zIndex: 100
    },
    item: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    }
}))
