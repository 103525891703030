import React, { RefObject, useRef } from 'react'
import { TextInput, View, ViewStyle } from 'react-native'
import Animated, { FlipInEasyY, FlipOutEasyY } from 'react-native-reanimated'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { isWeb, masks, Measurements } from 'lib/common'
import {
    Adapter,
    FormComponents,
    Grid,
    KeyboardAccessoryFieldProps,
    Touchable,
    Typography
} from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'

type QuantityPickerProps = {
    value: string,
    style?: ViewStyle,
    disabled?: boolean,
    adapterProps?: Field<string> & Partial<KeyboardAccessoryFieldProps>,
    textInputRef?: RefObject<TextInput>,
    onChangeValue(quantity: string): void
}

export const QuantityPicker: React.FunctionComponent<QuantityPickerProps> = ({
    disabled,
    value,
    style,
    adapterProps,
    textInputRef,
    onChangeValue
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const animatedModifier = useRef(0) // only for triggering entering/exiting animation
    const inputRef = useRef<TextInput>()

    return (
        <React.Fragment>
            <Typography.Title style={styles.quantityText}>
                {T.screens.itemDetails.fields.quantity}
            </Typography.Title>
            <Grid.Gap
                gapTop={2}
                gapBottom={5}
                style={{
                    ...styles.quantityWrapper,
                    ...conditionalStyle(isWeb, style ?? {})
                }}
            >
                <Touchable
                    disabled={disabled || Number(value) <= 1}
                    style={{
                        ...styles.iconWrapper,
                        ...conditionalStyle(Number(value) <= 1, styles.actionDisabled)
                    }}
                    onPress={() => {
                        (textInputRef?.current || inputRef.current)?.blur()
                        animatedModifier.current = animatedModifier.current - 1
                        onChangeValue(Math.max(1, Number(value) - 1).toFixed())
                    }}
                >
                    <Icons.Minus
                        size={24}
                        forceColor={theme.colors.lightGray}
                    />
                </Touchable>
                <View style={styles.inputWrapper}>
                    <Animated.View
                        key={`quantity-picker-${animatedModifier.current}`}
                        entering={FlipInEasyY.duration(150)}
                        exiting={FlipOutEasyY.duration(100)}
                    >
                        {adapterProps ? (
                            <Adapter.TextInput
                                ref={(textInputRef || inputRef) as RefObject<TextInput>}
                                {...adapterProps}
                                mask={masks.quantity}
                                label=""
                                clearDisabled
                                inputProps={{
                                    keyboardType: 'numeric',
                                    style: {
                                        ...styles.input,
                                        width: Math.max(130, 70 + value.length * 20)
                                    }
                                }}
                            />
                        ) : (
                            <FormComponents.Input
                                ref={(textInputRef || inputRef) as RefObject<TextInput>}
                                mask={masks.quantity}
                                clearDisabled
                                inputProps={{
                                    editable: !disabled,
                                    style: {
                                        ...styles.input,
                                        width: Math.max(130, 70 + (value?.length || 1) * 20)
                                    },
                                    value,
                                    onChangeText: onChangeValue,
                                    keyboardType: 'numeric'
                                }}
                            />
                        )}
                    </Animated.View>
                </View>
                <Touchable
                    disabled={disabled}
                    style={styles.iconWrapper}
                    onPress={() => {
                        (textInputRef?.current || inputRef.current)?.blur()
                        animatedModifier.current = animatedModifier.current + 1
                        onChangeValue(Math.max(1, Number(value) + 1).toFixed())
                    }}
                >
                    <Icons.Plus
                        size={24}
                        forceColor={theme.colors.lightGray}
                    />
                </Touchable>
            </Grid.Gap>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    quantityWrapper: {
        width: Measurements.WindowWidth - theme.utils.gap(6),
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    quantityText: {
        textAlign: 'center'
    },
    inputWrapper: {
        height: theme.components.input.height,
        borderWidth: 1,
        borderColor: theme.ui.accent,
        borderRadius: theme.components.input.borderRadius / 2
    },
    iconWrapper: {
        width: 56,
        height: 56,
        borderRadius: 28,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: theme.components.typography.secondary.color
    },
    input: {
        backgroundColor: undefined,
        color: theme.ui.primary,
        fontSize: 32,
        lineHeight: 37,
        textAlign: 'center'
    },
    actionDisabled: {
        borderColor: theme.colors.lightGray
    }
}))
