import React, { useMemo, useState } from 'react'
import { ActivityIndicator, FlatList } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { Icons } from 'assets'
import { Measurements } from 'lib/common'
import { Grid, Typography, FormComponents, SearchInput, Button, FadeShadowWrapper } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { VoidFunction, DropdownOption } from 'lib/types'
import { R } from 'lib/utils'

interface DropdownPickerContentProps<ValueType> {
    value: Array<DropdownOption<ValueType>>,
    items: Array<DropdownOption<ValueType>>,
    onClose: VoidFunction,
    disabled?: boolean,
    hasSearch?: boolean,
    searchPlaceholder?: string,
    emptyMessage: string,
    label?: string,
    title?: string,
    onFetchNextPage?: VoidFunction,
    isFetchingNextPage?: boolean,
    handleQuery?(query: string): void,
    renderLabel(item: DropdownOption<ValueType>): JSX.Element,
    onChangeValue(newItems: Array<DropdownOption<ValueType>>): void
}

export const DropdownMultiplePickerContent = <ValueType,>({
    value,
    onClose,
    items,
    disabled,
    hasSearch = false,
    searchPlaceholder = '',
    emptyMessage,
    label,
    title,
    renderLabel,
    onChangeValue,
    onFetchNextPage,
    isFetchingNextPage,
    handleQuery
}: DropdownPickerContentProps<ValueType>) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [query, setQuery] = useState('')
    const maxContainerHeight = Measurements.WindowHeight - Measurements.HeaderWithStatusBarHeight - theme.utils.gap(3)
    const itemsToDisplay = useMemo(() => {
        if (!query || handleQuery) {
            return items
        }

        return items.filter(item => item.key.includes(query))
    }, [items, query])
    const contentHeight = 220 + (90 + theme.utils.gap(4)) * itemsToDisplay.length
    const containerHeight = Math.max(
        320,
        Math.min(maxContainerHeight, contentHeight)
    )

    return (
        <Grid.Gap
            style={{
                ...styles.container,
                height: containerHeight
            }}
        >
            <Grid.Gap style={styles.modalHeader}>
                <Typography.Heading>
                    {title || label}
                </Typography.Heading>
            </Grid.Gap>
            {hasSearch && (
                <Grid.Gap gapBottom={2}>
                    <SearchInput
                        onDebouncedQuery={value => {
                            setQuery(value)

                            if (handleQuery) {
                                handleQuery(value)
                            }
                        }}
                        placeholder={searchPlaceholder}
                        leftIcon={(
                            <Icons.Search size={28}/>
                        )}
                    />
                </Grid.Gap>
            )}
            <FadeShadowWrapper
                showBottomShadow={contentHeight > maxContainerHeight}
                showTopShadow={contentHeight > maxContainerHeight}
                shadowHeight={theme.utils.gap(6)}
            >
                <FlatList
                    data={itemsToDisplay}
                    onEndReachedThreshold={0.8}
                    onEndReached={() => R.ifDefined(onFetchNextPage, R.call)}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={styles.scrollContainer}
                    scrollEnabled={maxContainerHeight < contentHeight}
                    keyExtractor={item => item.key}
                    renderItem={({ item }) => {
                        const isSelected = value.some(valueItem => valueItem.key === item.key)

                        return (
                            <Animated.View
                                key={item.key}
                                exiting={FadeOut}
                                entering={FadeIn}
                            >
                                <FormComponents.DropdownModalTile
                                    label={() => renderLabel(item)}
                                    disabled={disabled}
                                    isSelected={isSelected}
                                    onPress={() => {
                                        onChangeValue(isSelected
                                            ? value.filter(valueItem => valueItem.key !== item.key)
                                            : value.concat(item)
                                        )
                                    }}
                                />
                            </Animated.View>
                        )
                    }}
                    ListFooterComponent={() => (
                        <Grid.Gap
                            gapBottom={2}
                            gapTop={2}
                        >
                            {isFetchingNextPage && (
                                <ActivityIndicator/>
                            )}
                        </Grid.Gap>
                    )}
                    ListEmptyComponent={() => (
                        <Typography.Subheading style={styles.text}>
                            {emptyMessage}
                        </Typography.Subheading>
                    )}
                />
            </FadeShadowWrapper>
            <Button
                text={itemsToDisplay.length
                    ? T.common.save
                    : T.common.cancel
                }
                onPress={onClose}
            />
            <Grid.Gap gapBottom={8}/>
        </Grid.Gap>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingHorizontal: theme.utils.gap(4),
        backgroundColor: theme.ui.background
    },
    modalHeader: {
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    scrollContainer: {
        paddingTop: theme.utils.gap(4),
        paddingBottom: theme.utils.gap(8)
    },
    text: {
        textAlign: 'center'
    }
}))
