export enum MMKVKey {
    AccessToken = 'accessToken',
    RefreshToken = 'refreshToken',
    Locale = 'locale',
    IsAuthorized = 'isAuthorized',
    AuthProvider = 'authProvider',
    HasSavedBiometry = 'hasSavedBiometry',
    SeenWelcomeLogo = 'seenWelcomeLogo',
    ScannedItems = 'scannedItems',
    Stores = 'stores',
    Users = 'users',
    User = 'user',
    FilePath = 'filePath',
    ScanConfig = 'scanConfig',
    SearchItems = 'searchItems',
    CurrentStore = 'currentStore',
    SearchFilters = 'searchFilters'
}
