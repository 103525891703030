import React from 'react'
import { useSearchFilters } from 'lib/atoms'
import { SearchFilter } from 'lib/types'
import { Grid } from 'lib/components'
import { FiltersList } from '../components'

export const SearchFilters: React.FunctionComponent = () => {
    const [selectedFilters, setSelectedFilters] = useSearchFilters()

    const updateSelectedFilters = (filters: Array<SearchFilter>) => {
        setSelectedFilters(filters)
    }

    const disableEmptyFilters = () => {
        const filtersWithValue = selectedFilters.filter(filter => Boolean(filter.value))

        setSelectedFilters(filtersWithValue)
    }

    return (
        <Grid.Background>
            <FiltersList
                onBack={disableEmptyFilters}
                onUpdate={updateSelectedFilters}
            />
        </Grid.Background>
    )
}
