import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { StoreItem } from 'lib/models'
import { EditItemFormFields } from './forms'

export const useEditItemForm = (item: StoreItem) => {
    const T = useTranslations()

    const bookCode = useField<string>({
        key: EditItemFormFields.BookCode,
        initialValue: item.bookCode,
        label: T.forms.editItem.bookCode.label,
        placeholder: T.forms.editItem.bookCode.placeholder,
        isRequired: true
    })

    const quantity = useField<string>({
        key: EditItemFormFields.Quantity,
        initialValue: `${item?.quantity}` || '',
        label: T.forms.editItem.quantity.label,
        placeholder: T.forms.editItem.quantity.placeholder,
        isRequired: true,
        validateOnBlur: true,
        validationRules: [
            {
                errorMessage: T.forms.editItem.quantity.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        bookCode,
        quantity
    }
}
