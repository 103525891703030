import React, { useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import * as SystemUI from 'expo-system-ui'
import { useGetMeWatcher } from 'features/user'
import { useStyles } from 'lib/styles'
import { NativeStack } from './NativeStack'
import { AuthStack } from './AuthStack'
import { ScreenNames, ScreensConfig } from './screens'
import { NavigationScreenParams } from './stackParams'
import { useIsAuthorizedAtom, useUserAtom } from '../atoms'

const prefix = Linking.createURL('')

export const AppRouter: React.FunctionComponent = () => {
    const { theme } = useStyles()
    const [isAuthorized] = useIsAuthorizedAtom()
    const [user] = useUserAtom()

    useEffect(() => {
        SystemUI.setBackgroundColorAsync(theme.ui.background)
    }, [])

    useGetMeWatcher()

    return (
        <NavigationContainer<NavigationScreenParams>
            documentTitle={{
                formatter: options => options?.title ?? ''
            }}
            linking={{
                prefixes: [prefix],
                config: {
                    initialRouteName: !isAuthorized
                        ? ScreenNames.Welcome
                        : undefined,
                    screens: {
                        ...!isAuthorized
                            ? {
                                [ScreenNames.ResetPassword]: 'reset-password',
                                [ScreenNames.VerifyEmail]: 'confirm-account',
                                [ScreenNames.ConfirmEmailChange]: 'confirm-email',
                                [ScreenNames.SetupPassword]: 'setup-password' // todo sync with api
                            }
                            : {
                                [ScreenNames.ConfirmEmailChange]: 'confirm-email'
                            },
                        ...ScreensConfig
                    }
                }
            }}
        >
            {isAuthorized
                ? NativeStack(user.userType)
                : AuthStack()
            }
        </NavigationContainer>
    )
}
