import React, { useMemo, useState } from 'react'
import { TextInput, TextInputProps, TextStyle, View } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import ReactNativeMaskInput, { Mask } from 'react-native-mask-input'
import { Icons } from 'assets'
import { Touchable } from 'lib/components'
import { R } from 'lib/utils'
import { useStyles } from 'lib/styles'
import { ErrorMessage } from './ErrorMessage'
import { useTextInput } from './hooks'

type InputProps = {
    label?: string,
    errorMessage?: string,
    isPassword?: boolean,
    clearDisabled?: boolean,
    mask?: Mask,
    inputProps: TextInputProps,
    labelStyle?: TextStyle,
    onPress?: VoidFunction,
    leftIcon?: JSX.Element,
    rightIcon?: JSX.Element,
    disableClearButton?: boolean,
    onDebounceEnd?(inputValue: string): void
}

export const Input = React.forwardRef<TextInput, InputProps>((props, ref) => {
    const [isPasswordVisible, setPasswordVisibility] = useState(false)
    const { theme } = useStyles()
    const rightPasswordIcon = useMemo(() => (
        <Touchable onPress={() => setPasswordVisibility(prevState => !prevState)}>
            <Animated.View
                key={`passwordIcon-${isPasswordVisible}`}
                entering={FadeIn}
                exiting={FadeOut}
            >
                {!isPasswordVisible ? (
                    <Icons.EyeCrossed size={20}/>
                ) : (
                    <Icons.Eye size={20}/>
                )}
            </Animated.View>
        </Touchable>
    ), [isPasswordVisible])

    const {
        styles,
        inputProps,
        errorMessage,
        renderLeftIcon,
        renderRightIcon,
        renderClearButton,
        renderLabel
    } = useTextInput({
        ...props,
        clearDisabled: (props.disableClearButton || props.clearDisabled),
        hasValue: Boolean(props.inputProps.value),
        rightIcon: (props.isPassword && props.inputProps.value)
            ? rightPasswordIcon
            : props.rightIcon
    })

    return (
        <View style={styles.container}>
            <View style={styles.container}>
                {props?.mask ? (
                    <ReactNativeMaskInput
                        ref={ref}
                        mask={props.mask}
                        placeholderFillCharacter={''}
                        {...inputProps as TextInputProps}
                        onChangeText={(masked, unmasked) => inputProps.onChangeText(unmasked)}
                        onPressIn={() => R.ifDefined(props.onPress, R.call)}
                    />
                ) : (
                    <TextInput
                        ref={ref}
                        {...inputProps as TextInputProps}
                        placeholderTextColor={theme.colors.lightGray}
                        secureTextEntry={props.isPassword && !isPasswordVisible}
                        onPressIn={() => R.ifDefined(props.onPress, R.call)}
                    />
                )}
                {renderLeftIcon()}
                {renderRightIcon()}
                {renderClearButton()}
                {renderLabel()}
                <ErrorMessage text={errorMessage} />
            </View>
        </View>
    )
})
