import React, { useState } from 'react'
import { View } from 'react-native'
import { useScannedItemsAtom } from 'lib/atoms'
import { Measurements } from 'lib/common'
import { Button, FormComponents, GoBack, Grid, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { ItemDetailTile } from '../components'

type ItemDetailsScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.ItemDetails>
}
export const ItemDetailsScreen: React.FunctionComponent<ItemDetailsScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [, setScannedItems] = useScannedItemsAtom()
    const [quantity, setQuantity] = useState(route.params.storeItem.quantity.toFixed())
    const details = route.params.storeItem

    const onSubmit = () => {
        setScannedItems(prevState => {
            const otherItems = prevState.filter(item => item.bookCode !== details.bookCode)

            return otherItems.concat({
                ...details,
                quantity: Number(quantity)
            })
        })

        navigation.goBack()
    }

    return (
        <Grid.Background hasPaddingHorizontal>
            <GoBack onPress={navigation.goBack}/>
            <Typography.Heading>
                {T.screens.itemDetails.title}
            </Typography.Heading>
            <Grid.Gap gapBottom={5}/>
            <ItemDetailTile
                label={T.screens.itemDetails.fields.title}
                value={details?.title || ''}
            />
            <ItemDetailTile
                label={T.screens.itemDetails.fields.author}
                value={details?.author || ''}
            />
            <ItemDetailTile
                label={T.screens.itemDetails.fields.category}
                value={details?.category || ''}
            />
            <ItemDetailTile
                label={T.screens.itemDetails.fields.sku}
                value={details?.sku?.toLocaleString() || ''}
            />
            <ItemDetailTile
                label={T.screens.itemDetails.fields.bookCode}
                value={details.bookCode}
            />
            <View style={styles.container}>
                <View style={styles.spacer}/>
                <Grid.Gap
                    gapTop={2}
                    gapBottom={2}
                >
                    <FormComponents.QuantityPicker
                        value={quantity}
                        onChangeValue={setQuantity}
                    />
                </Grid.Gap>
                <View style={styles.spacer}/>
                <Button
                    width={Measurements.WindowWidth - theme.utils.gap(6)}
                    text={T.common.confirm}
                    onPress={onSubmit}
                />
                <Grid.Gap gapBottom={3}/>
                <Button
                    noBackground
                    width={Measurements.WindowWidth - theme.utils.gap(6)}
                    text={T.common.cancel}
                    onPress={navigation.goBack}
                />
            </View>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingBottom: theme.utils.gap(8)
    },
    spacer: {
        flex: 1
    }
}))
