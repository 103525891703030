import React from 'react'
import { View } from 'react-native'
import { Button } from 'lib/components'
import { useGoBack, useTranslations } from 'lib/hooks'
import { createStyles, useStyles } from 'lib/styles'
import { ScreenNames } from 'lib/routing'

type AddEditUserButtonsProps = {
    isLoading?: boolean,
    submit: VoidFunction,
    onClear?: VoidFunction
}

export const AddEditUserButtons: React.FunctionComponent<AddEditUserButtonsProps> = ({
    onClear,
    submit,
    isLoading
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { goBack } = useGoBack(ScreenNames.ManageUsers)

    return (
        <View style={styles.container}>
            {onClear && (
                <Button
                    customStyle={styles.textInput}
                    noBackground
                    disabled={isLoading}
                    text={T.common.cancel}
                    onPress={() => {
                        onClear()
                        goBack()
                    }}
                />
            )}
            <Button
                customStyle={styles.textInput}
                isLoading={isLoading}
                text={T.common.save}
                onPress={submit}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        justifyContent: {
            xs: 'center',
            sm: 'space-between'
        },
        gap: theme.gap * 2,
        marginTop: {
            sm: theme.gap * 2
        }
    },
    textInput: {
        flexShrink: 1,
        width: '100%'
    }
}))
