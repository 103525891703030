import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useScannedItemsAtom } from 'lib/atoms'
import { Measurements } from 'lib/common'
import { Button, ConfirmModal, FadeShadowWrapper, GoBack, Grid, TableNative, Touchable, Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { Action, TableAction, TableItemKey, TooltipPlacement } from 'lib/types'
import { ScannedItemsTableItem } from '../types'

type ScannedItemsScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.ScannedItems>
}

export const ScannedItemsScreen: React.FunctionComponent<ScannedItemsScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [scannedItems, setScannedItems] = useScannedItemsAtom()
    const [itemToDeleteBookCode, setItemToDeleteBookCode] = useState('')

    const tableActions = [
        {
            key: TableAction.Edit,
            label: T.components.table.actions.edit,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Pencil
                    size={22}
                    forceColor={theme.components.typography.primary.color}
                />
            ),
            action: item => navigation.navigate(ScreenNames.EditItemModal, { item })
        },
        {
            key: TableAction.Remove,
            label: T.components.table.actions.remove,
            tooltipPlacement: TooltipPlacement.Left,
            icon: (
                <Icons.Bin
                    size={20}
                    forceColor={theme.components.typography.primary.color}
                />
            ),
            action: row => setItemToDeleteBookCode(row.bookCode)
        }
    ] satisfies Array<Action<ScannedItemsTableItem>>

    return (
        <Grid.Background
            hasPaddingHorizontal
            style={styles.background}
        >
            <GoBack/>
            <View style={styles.header(Boolean(route.params?.showScannerButton))}>
                {route.params?.showScannerButton && (
                    <View style={styles.placeHolder} />
                )}
                <Typography.Heading>
                    {T.screens.scannedItems.title}
                </Typography.Heading>
                {route.params?.showScannerButton && (
                    <Touchable
                        onPress={() => navigation.navigate(ScreenNames.Scanner, {
                            store: route.params.store
                        })}
                    >
                        <Icons.Barcode
                            size={32}
                            forceColor={theme.ui.primary}
                        />
                    </Touchable>
                )}
            </View>

            <Grid.Gap gapBottom={5}/>
            <FadeShadowWrapper
                containerStyle={styles.fadeShadowWrapper}
                showTopShadow={false}
                showBottomShadow={scannedItems?.length > 0}
            >
                <TableNative
                    columns={[
                        {
                            key: TableItemKey.BookCode,
                            title: T.components.table.headers.bookCode,
                            size: 130
                        },
                        {
                            key: TableItemKey.Quantity,
                            title: T.components.table.headers.quantity
                        }
                    ]}
                    data={scannedItems.map(item => ({
                        bookCode: item.bookCode,
                        quantity: item.quantity,
                        storeItemUUID: item.storeItemUUID,
                        sku: item.sku
                    }))}
                    actions={tableActions}
                    listEmptyComponent={() => (
                        <Typography.Subheading style={styles.text}>
                            {T.screens.scannedItems.listEmpty.message}
                        </Typography.Subheading>
                    )}
                />
            </FadeShadowWrapper>
            {scannedItems.length > 0 && (
                <React.Fragment>
                    <Grid.Gap gapBottom={2}/>
                    <Button
                        disabled={!scannedItems.length}
                        width={Measurements.WindowWidth - theme.utils.gap(6)}
                        text={T.screens.scannedItems.finishButton}
                        onPress={() => navigation.navigate(ScreenNames.FinishScanning, {
                            store: route.params.store
                        })}
                    />
                </React.Fragment>
            )}
            <Grid.Gap gapBottom={8}/>
            <ConfirmModal
                isVisible={Boolean(itemToDeleteBookCode)}
                message={T.screens.scannedItems.confirmDelete}
                onClose={() => setItemToDeleteBookCode('')}
                onConfirm={() => {
                    setScannedItems(prevState => prevState.filter(scannedItem => scannedItem.bookCode !== itemToDeleteBookCode))
                    setItemToDeleteBookCode('')
                }}
            />
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingBottom: theme.utils.gap(1.5),
        paddingRight: theme.utils.gap(6), // icons_width + tile_padding_right,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.mixTransparent(theme.ui.accent, 0.5)
    },
    containerFlexed: {
        flex: 1,
        justifyContent: 'center'
    },
    containerPadded: {
        paddingBottom: theme.utils.gap(8)
    },
    text: {
        textAlign: 'center',
        padding: theme.utils.gap(3)
    },
    message: {
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(3)
    },
    fadeShadowWrapper: {
        width: '100%'
    },
    background: {
        alignItems: 'center'
    },
    header: (withRightButton: boolean) => ({
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: withRightButton
            ? 'space-between'
            : 'center'
    }),
    placeHolder: {
        width: theme.gap * 4
    }
}))
