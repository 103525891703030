// eslint-disable-next-line no-restricted-imports
import { GestureResponderEvent, LayoutChangeEvent, Pressable, ViewStyle } from 'react-native'
import React, { useState } from 'react'
import { R } from 'lib/utils'
import { Children, VoidFunction } from '../types'

type TouchableProps = {
    focusable?: boolean,
    onLongPress?: VoidFunction,
    onPressOut?: VoidFunction,
    disabled?: boolean,
    hitSlopLeft?: number,
    disableStyle?: boolean,
    hitSlopRight?: number,
    hitSlopTop?: number,
    hitSlopBottom?: number,
    style?: ViewStyle,
    activeOpacity?: number,
    children?: Children,
    onPress?(event: GestureResponderEvent): void,
    onLayout?(event: LayoutChangeEvent): void
}

export const Touchable: React.FunctionComponent<TouchableProps> = ({
    onPress,
    children,
    disabled,
    style,
    disableStyle,
    onLongPress,
    onPressOut,
    focusable = true,
    hitSlopLeft = 0,
    hitSlopRight = 0,
    hitSlopTop = 0,
    hitSlopBottom = 0,
    activeOpacity = 0.6,
    onLayout
}) => {
    const [pressedIn, setPressedIn] = useState(false)

    return (
        <Pressable
            focusable={focusable}
            children={children}
            android_disableSound
            disabled={disabled}
            onPress={onPress}
            onLongPress={onLongPress}
            style={({ pressed }) => ({
                ...style,
                opacity: !disableStyle && (pressed || pressedIn) ? activeOpacity : 1
            })}
            hitSlop={{
                bottom: hitSlopBottom,
                top: hitSlopTop,
                left: hitSlopLeft,
                right: hitSlopRight
            }}
            onPressIn={() => setPressedIn(true)}
            onPressOut={() => {
                setPressedIn(false)
                R.ifDefined(onPressOut, R.call)
            }}
            onLayout={onLayout}
        />
    )
}
