import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { SignInWithEmailFormFields } from './forms'

export const useSignInWithEmailForm = () => {
    const T = useTranslations()

    const email = useField<string>({
        key: SignInWithEmailFormFields.Email,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.common.email.label,
        placeholder: T.forms.common.email.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.email.validation.isValid,
                validate: regexes.isValidEmail
            },
            {
                errorMessage: T.forms.common.email.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ],
        liveParser: value => value.toLowerCase()
    })
    const password = useField<string>({
        key: SignInWithEmailFormFields.Password,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.common.password.label,
        placeholder: T.forms.common.password.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.password.validation.isValid,
                validate: regexes.isValidPassword
            },
            {
                errorMessage: T.forms.common.password.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    return {
        email,
        password
    }
}
