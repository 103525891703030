import React, { useEffect } from 'react'
import { Alert, View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { isNative, isWeb } from 'lib/common'
import { AppLayout, Grid, NavigationHeader, ReKeyboardAccessory } from 'lib/components'
import { useBackHandler, useGoBack, useToast, useTranslations } from 'lib/hooks'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { createStyles, useStyles } from 'lib/styles'
import { QueryKey, queryClient } from 'lib/api'
import { R } from 'lib/utils'
import { useStoreSetupForm, StoreSetupFields } from '../forms'
import { useAddNewStore, useEditStore, useGetCountries } from '../actions'
import { AddNewStoreRequest, EditStoreRequest } from '../types'
import { StoreSetup } from '../components'

type StoreSetupScreenProps = {
    route: NavigationParams<ScreenNames.StoreSetup>
}

export const StoreSetupScreen: React.FunctionComponent<StoreSetupScreenProps> = ({ route }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { actions: { showSuccessToast } } = useToast()
    const { mutate: addNewStore, isLoading: isAddingNewStore } = useAddNewStore()
    const { mutate: editStore, isLoading: isEditingStore } = useEditStore()
    const isLoading = isAddingNewStore || isEditingStore
    const {
        refetch: getCountries,
        isLoading: isFetchingCountries,
        data: countries
    } = useGetCountries()
    const initialCountry = countries?.data?.find(country => country?.countryName === route.params?.country)
    const initialState = initialCountry?.states?.find(state => state?.stateName === route.params?.state)
    const { goBack } = useGoBack(ScreenNames.ManageStores)
    const { form, submit, setFieldInitialValue, setFieldValue } = useForm(useStoreSetupForm(route.params, initialCountry, initialState), {
        onSuccess: ({ country, state, ...formValues }) => {
            const newStore: AddNewStoreRequest = {
                ...formValues,
                countryUUID: country?.countryUUID,
                stateUUID: state?.stateUUID
            }

            if (route.params) {
                const editedStore: EditStoreRequest = {
                    storeUUID: route.params.storeUUID,
                    ...newStore
                }

                return editStore(editedStore, {
                    onSuccess: () => {
                        isNative
                            ? queryClient.invalidateQueries([QueryKey.InfiniteStores])
                            : queryClient.invalidateQueries([QueryKey.Stores])

                        showSuccessToast(T.screens.storeSetup.toast.editSuccess)
                        goBack()
                    },
                    onError: () => Alert.alert(T.alerts.oops, T.screens.storeSetup.errorEdit)
                })
            }

            addNewStore(newStore, {
                onSuccess: () => {
                    isNative
                        ? queryClient.invalidateQueries([QueryKey.InfiniteStores])
                        : queryClient.invalidateQueries([QueryKey.Stores])

                    showSuccessToast(T.screens.storeSetup.toast.addSuccess)
                    goBack()
                },
                onError: () => Alert.alert(T.alerts.oops, T.screens.storeSetup.errorAdd)
            })
        }
    })

    useEffect(() => {
        getCountries()
            .catch(R.T)
    }, [])

    useEffect(() => {
        setFieldInitialValue(StoreSetupFields.Country, initialCountry)
        setFieldInitialValue(StoreSetupFields.State, initialState)
    }, [initialCountry, initialState])

    useEffect(() => {
        setFieldInitialValue(StoreSetupFields.City, route.params?.city ?? '')
        setFieldInitialValue(StoreSetupFields.StoreDBA, route.params?.storeDBA ?? '')
        setFieldInitialValue(StoreSetupFields.StoreName, route.params?.storeName ?? '')
        setFieldInitialValue(StoreSetupFields.StreetAddress, route.params?.streetAddress ?? '')
        setFieldInitialValue(StoreSetupFields.ZipCode, route.params?.zipCode ?? '')
    }, [route.params])

    useBackHandler(() => isLoading)

    const clearState = () => setFieldValue(StoreSetupFields.State, undefined)

    return (
        <AppLayout>
            <Grid.Background
                hasPaddingHorizontal={isWeb}
                hasPaddingVertical={isWeb}
            >
                <NavigationHeader hasMarginHorizontal/>
                {isNative ? (
                    <ReKeyboardAccessory numberOfInputs={5}>
                        {keyboardProps => {
                            const [
                                storeNameProps,
                                storeDBAProps,
                                streetAddressProps,
                                cityProps,
                                zipCodeProps
                            ] = keyboardProps

                            return (
                                <StoreSetup
                                    form={form}
                                    countries={countries?.data}
                                    isLoading={isLoading || isFetchingCountries}
                                    submit={submit}
                                    cityProps={cityProps}
                                    storeNameProps={storeNameProps}
                                    storeDBAProps={storeDBAProps}
                                    streetAddressProps={streetAddressProps}
                                    zipCodeProps={zipCodeProps}
                                    isFetchingCountries={isFetchingCountries}
                                    onCountryChange={clearState}
                                />
                            )
                        }}
                    </ReKeyboardAccessory>
                ) : (
                    <View style={styles.formWrapper}>
                        <StoreSetup
                            form={form}
                            countries={countries?.data}
                            isLoading={isLoading || isFetchingCountries}
                            submit={submit}
                            isFetchingCountries={isFetchingCountries}
                            onCountryChange={clearState}
                        />
                    </View>
                )}
            </Grid.Background>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    formWrapper: {
        width: '100%',
        borderRadius: 12,
        maxWidth: {
            sm: 728
        },
        padding: {
            sm: 24
        },
        backgroundColor: {
            sm: theme.colors.littleMoreDarkerGray
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    goback: {
        top: 0
    }
}))
