import React from 'react'
import { ViewStyle } from 'react-native'
import { VoidFunction } from 'lib/types'
import { FormComponents, Grid, Touchable } from 'lib/components'
import { createStyles, useStyles } from 'lib/styles'

type LanguageTileProps = {
    isSelected: boolean,
    disabled?: boolean,
    customStyles?: ViewStyle,
    onPress: VoidFunction,
    label(): JSX.Element
}

export const DropdownModalTile: React.FunctionComponent<LanguageTileProps> = ({
    isSelected,
    disabled,
    customStyles,
    onPress,
    label
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            style={{
                ...styles.container,
                ...customStyles
            }}
            onPress={onPress}
            disabled={disabled}
        >
            <Grid.Gap style={styles.column}>
                {label()}
            </Grid.Gap>
            <FormComponents.Switch
                isToggled={isSelected}
                onToggle={onPress}
            />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        minHeight: 56,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: theme.utils.gap(1),
        padding: theme.utils.gap(2),
        borderRadius: theme.components.button.borderRadius,
        backgroundColor: theme.ui.foreground
    },
    column: {
        justifyContent: 'center'
    }
}))
