import { useField } from '@codegateinc/react-form-builder-v2'
import { useUserAtom } from 'lib/atoms'
import { useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { AdminSetupFormFields } from './forms'

export const useAdminSetupForm = () => {
    const [user] = useUserAtom()
    const T = useTranslations()

    const fullName = useField<string>({
        key: AdminSetupFormFields.FullName,
        initialValue: user?.fullName || '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.common.fullName.label,
        placeholder: T.forms.common.fullName.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.fullName.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.forms.common.fullName.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    const email = useField<string>({
        key: AdminSetupFormFields.Email,
        initialValue: user?.email || '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.common.email.label,
        placeholder: T.forms.common.email.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.email.validation.isValid,
                validate: regexes.isValidEmail
            },
            {
                errorMessage: T.forms.common.email.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ],
        liveParser: value => value.toLowerCase()
    })

    const password = useField<string>({
        key: AdminSetupFormFields.Password,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.common.password.label,
        placeholder: T.forms.common.password.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.password.validation.isValid,
                validate: regexes.isValidPassword
            },
            {
                errorMessage: T.forms.common.password.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ]
    })

    return {
        fullName,
        email,
        password
    }
}
