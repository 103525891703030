import { TextInputProps } from 'react-native'

export const name: TextInputProps = {
    autoComplete: 'name',
    autoCapitalize: 'words',
    textContentType: 'name'
}

export const email: TextInputProps = {
    autoCorrect: false,
    keyboardType: 'email-address',
    autoComplete: 'email',
    autoCapitalize: 'none',
    textContentType: 'emailAddress'
}

export const phoneNumber: TextInputProps = {
    autoCorrect: false,
    keyboardType: 'phone-pad',
    autoComplete: 'tel-country-code',
    textContentType: 'telephoneNumber',
    dataDetectorTypes: 'phoneNumber'
}

export const password: TextInputProps = {
    autoCorrect: false,
    textContentType: 'password',
    autoComplete: 'password',
    autoCapitalize: 'none'
}

export const newPassword: TextInputProps = {
    ...password,
    textContentType: 'newPassword',
    passwordRules: 'minlength: 8; required: lower; required: upper; required: digit;'
}

export const bookCode: TextInputProps = {
    autoCorrect: false,
    keyboardType: 'number-pad'
}

export const author: TextInputProps = {
    autoCapitalize: 'words',
    textContentType: 'name',
    autoComplete: 'name'
}

export const bypassAutocomplete: TextInputProps = {
    autoComplete: 'new-password'
}

export const bypassIosStrongPassword: TextInputProps = {
    textContentType: 'oneTimeCode'
}
