import { useResetIsAuthorizedAtom } from './auth'
import { useResetLocaleAtom } from './locale'
import { useResetScannedItemsAtom } from './scannedItems'
import { useResetStoresAtom } from './stores'
import { useResetUsersAtom } from './users'
import { useResetUserAtom } from './user'
import { useResetAuthProviderAtom } from './authProvider'
import { useResetScanConfigAtom } from './scanConfig'

export const useResetAtoms = () => {
    const [, resetIsAuthorizedAtom] = useResetIsAuthorizedAtom()
    const [, resetLocaleAtom] = useResetLocaleAtom()
    const [, resetScannedItemsAtom] = useResetScannedItemsAtom()
    const [, resetStoresAtom] = useResetStoresAtom()
    const [, resetUsersAtom] = useResetUsersAtom()
    const [, resetUserAtom] = useResetUserAtom()
    const [, resetAuthProvider] = useResetAuthProviderAtom()
    const [, resetScanConfig] = useResetScanConfigAtom()

    return {
        resetAtoms: () => [
            resetIsAuthorizedAtom,
            resetLocaleAtom,
            resetScannedItemsAtom,
            resetStoresAtom,
            resetUsersAtom,
            resetUserAtom,
            resetAuthProvider,
            resetScanConfig
        ].forEach(reset => reset())
    }
}
