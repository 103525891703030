import React, { useEffect, useRef, useState } from 'react'
import { TextInput, View, useWindowDimensions } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { FlatList } from 'react-native-gesture-handler'
import { conditionalStyle, createStyles, useStyles } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { DropdownProps } from 'lib/types'
import { AnimatedArrow, Grid, Touchable, Typography } from 'lib/components'
import { R } from 'lib/utils'
import { isNative, isWeb } from 'lib/common'
import { Input } from './TextInput'

const INPUT_HEIGHT = 45
const MAX_HEIGHT = 300

export const Dropdown = <DropdownValue,>({
    items,
    style,
    search,
    disabled,
    value,
    placeholder,
    onToggle,
    getItemLabel,
    renderCustomItem,
    onChangeValue,
    getItemKey,
    onBlur,
    onChange,
    ...inputFormProps
}: DropdownProps<DropdownValue>) => {
    const T = useTranslations()
    const inputRef = useRef<TextInput>(null)
    const [searchTerm, setSearchTerm] = useState<string>()
    const [isExpanded, setIsExpanded] = useState(false)
    const { styles, theme } = useStyles(stylesheet)
    const listHeight = useSharedValue(0)
    const dimensions = useWindowDimensions()
    const [availableHeight, setAvailableHeight] = useState(0)
    const [fromTop, setFromTop] = useState(0)
    const listStyle = useAnimatedStyle(() => ({
        height: listHeight.value
    }))
    const filteredItems = searchTerm
        ? items.filter(item => getItemLabel?.(item).toLowerCase().includes(searchTerm.toLowerCase()))
        : items

    const getInputColor = R.cond([
        [R.always(isExpanded), R.always(theme.ui.primary)],
        [R.always(Boolean(disabled)), R.always(theme.colors.lighterGray)],
        [R.T, R.always(theme.components.input.typography.text)]
    ])

    const getInputValue = R.cond([
        [R.always(searchTerm !== undefined), R.always(searchTerm)],
        [R.always(Boolean(value)), R.always(getItemLabel?.(value))],
        [R.T, R.always('')]
    ])

    const toggle = () => {
        onToggle?.(isExpanded)

        if (!isExpanded) {
            setIsExpanded(true)

            return listHeight.value = withTiming(Math.min(MAX_HEIGHT, availableHeight, INPUT_HEIGHT * items.length
                ? INPUT_HEIGHT * items.length
                : INPUT_HEIGHT
            ))
        }

        setIsExpanded(false)

        listHeight.value = withTiming(0)
    }

    useEffect(() => {
        setAvailableHeight(dimensions.height - fromTop - theme.components.input.height - theme.gap * 2)
    }, [dimensions, fromTop])

    useEffect(() => {
        if (!isExpanded && search) {
            return setSearchTerm(undefined)
        }

        if (isExpanded && search && inputRef.current) {
            inputRef.current.focus()
            setSearchTerm('')
        }
    }, [isExpanded])

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
        >
            {isWeb && isExpanded && (
                <Touchable
                    onPress={() => toggle()}
                    style={styles.outside}
                />
            )}
            <Touchable
                onPress={toggle}
                activeOpacity={isNative ? 1 : undefined}
                onLayout={event => setFromTop(event.nativeEvent.layout.top ?? 0)}
            >
                <Input
                    ref={inputRef}
                    clearDisabled
                    rightIcon={(
                        <AnimatedArrow isRotated={isExpanded} />
                    )}
                    {...inputFormProps}
                    errorMessage={!isExpanded ? inputFormProps.errorMessage : undefined}
                    inputProps={{
                        numberOfLines: 1,
                        value: getInputValue(),
                        placeholder,
                        onChangeText: value => search && setSearchTerm(value),
                        placeholderTextColor: theme.components.input.typography.placeholder,
                        editable: (Boolean(search) && !R.isEmpty(items)),
                        focusable: false,
                        style: {
                            backgroundColor: disabled
                                ? theme.ui.foreground
                                : theme.components.input.backgroundColor,
                            color: getInputColor(),
                            paddingRight: 50,
                            ...style,
                            ...conditionalStyle(isNative, styles.disablePointerEvents)
                        },
                        onBlur: () => R.ifDefined(onBlur, () => onBlur?.())
                    }}
                />
            </Touchable>
            <Animated.View
                style={[
                    listStyle,
                    styles.list
                ]}
            >
                <FlatList
                    nestedScrollEnabled
                    data={filteredItems}
                    style={styles.flatList}
                    keyExtractor={item => getItemKey?.(item) || ''}
                    renderItem={({ item, index }) => renderCustomItem ? (
                        <Touchable
                            onPress={() => {
                                onChangeValue(item)
                                toggle()
                            }}
                        >
                            <React.Fragment>
                                {renderCustomItem(item, index)}
                            </React.Fragment>
                        </Touchable>
                    ) : (
                        <Touchable
                            disabled={disabled}
                            style={{
                                ...styles.tile,
                                ...conditionalStyle(index === 0, styles.firstItem)
                            }}
                            key={getItemKey?.(item)}
                            onPress={() => {
                                onChange?.(item)
                                onChangeValue(item)
                                toggle()
                            }}
                        >
                            <Typography.Regular
                                numberOfLines={1}
                                style={styles.listElement}
                                forceColor={isExpanded && getItemKey?.(item) === getItemKey?.(value)
                                    ? theme.ui.primary
                                    : theme.components.typography.secondary.color
                                }
                            >
                                {getItemLabel?.(item)}
                            </Typography.Regular>
                            {index < filteredItems.length - 1 && (
                                <Grid.Gap
                                    gapTop={1}
                                    style={styles.spacer}
                                />
                            )}
                        </Touchable>
                    )}
                    showsVerticalScrollIndicator={false}
                    ListEmptyComponent={() => (
                        <View style={styles.tile}>
                            <Typography.Regular
                                numberOfLines={1}
                                style={styles.listElement}
                                forceColor={theme.icon.error}
                            >
                                {T.components.table.noResults}
                            </Typography.Regular>
                        </View>
                    )}
                />
            </Animated.View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    disablePointerEvents: {
        pointerEvents: 'none'
    },
    list: {
        position: 'absolute',
        zIndex: 101,
        backgroundColor: theme.ui.foreground,
        top: INPUT_HEIGHT,
        width: '100%',
        borderBottomRightRadius: theme.components.input.borderRadius,
        borderBottomLeftRadius: theme.components.input.borderRadius
    },
    input: {
        cursor: 'pointer'
    },
    flatList: {
        backgroundColor: theme.components.input.backgroundColor,
        borderBottomRightRadius: theme.components.input.borderRadius,
        borderBottomLeftRadius: theme.components.input.borderRadius
    },
    firstItem: {
        marginTop: theme.gap / 2
    },
    tile: {
        width: '100%',
        justifyContent: 'center',
        paddingHorizontal: 20,
        paddingTop: theme.gap / 2,
        paddingBottom: theme.gap
    },
    spacer: {
        backgroundColor: theme.colors.mixTransparent(theme.components.typography.secondary.color, 0.25),
        height: 1
    },
    listElement: {
        fontSize: 14,
        lineHeight: 20
    },
    searchHeight: {
        height: 'auto',
        maxHeight: MAX_HEIGHT
    },
    noResultsHeight: {
        height: MAX_HEIGHT
    },
    outside: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        inset: 0,
        zIndex: 100
    }
}))
