import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { isWeb } from 'lib/common'
import { BlurView } from 'expo-blur'
import { conditionalStyle, createStyles, useStyles } from '../styles'

type FullScreenLoaderProps = {
    isActive: boolean,
    color?: string
}

export const FullScreenLoader: React.FunctionComponent<FullScreenLoaderProps> = ({
    isActive,
    color
}) => {
    const { styles, theme } = useStyles(stylesheet)

    if (!isActive) {
        return null
    }

    return (
        <View style={styles.container}>
            <BlurView
                style={conditionalStyle(isWeb, styles.blur)}
                tint="dark"
                intensity={2}
                // todo
                // reducedTransparencyFallbackColor={theme.colors.mixTransparent(theme.colors.black, 0.5)}
            />
            <ActivityIndicator color={color || theme.colors.white} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1001
    },
    blur: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: theme.colors.mixTransparent(theme.colors.black, 0.5)
    }
}))
