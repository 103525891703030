import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { regexes } from 'lib/utils'
import { ForgotPasswordFormFields } from './forms'

export const useForgotPasswordForm = () => {
    const T = useTranslations()

    const email = useField<string>({
        key: ForgotPasswordFormFields.Email,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        label: T.forms.common.email.label,
        placeholder: T.forms.common.email.placeholder,
        validationRules: [
            {
                errorMessage: T.forms.common.email.validation.isValid,
                validate: regexes.isValidEmail
            },
            {
                errorMessage: T.forms.common.email.validation.isTooLong,
                validate: value => value.length <= 255
            }
        ],
        liveParser: value => value.toLowerCase()
    })

    return {
        email
    }
}
